import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/oeffnungszeiten',
    name: 'openindHours',
    component: () => import(/* webpackChunkName: "openindHours" */ '../views/OpeningHours.vue')
  },
  {
    path: '/menue',
    name: 'menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/Menu.vue')
  },
  {
    path: '/weekmenu',
    name: 'weekmenu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/WeekMenu.vue')
  },
  
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import(/* webpackChunkName: "kontakt" */ '../views/Contact.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue')
  },
  {
    path:'/gallerie/:tagName',
    name: 'galleryPreview',
    component: () => import(/* webpackChunkName: "galleryPreview" */'../views/GalleryPreview.vue')
  },
  {
    path:'/gallerie/:tagName/:id',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "gallery" */'../views/Gallery.vue')
  },
  {
    path:'/trip',
    name: 'trip',
    component: () => import(/* webpackChunkName: "gallery" */'../views/Trip.vue')
  },
  {
    path:'/gasthaus',
    name: 'gasthaus',
    component: () => import(/* webpackChunkName: "gallery" */'../views/Restaurant.vue')
  },
  {
    path:'/catering',
    name: 'catering',
    component: () => import(/* webpackChunkName: "gallery" */'../views/Catering.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
