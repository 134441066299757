<template>
<div class="home container has-text-centered">
          <div v-if="pending" class="tile is-ancestor">
            <div class="tile is-4 is-vertical is-parent">
              <div class="tile is-child">
                <b-image
                  :src="logoURL"
                  alt="A random image"
                ></b-image> 
                <br/>
                <b-image
              :src="FamilyPicURL"
              alt="A random image"
            ></b-image>       
              </div>
           
        </div>
      <div class="tile is-vertical is-parent">
        <div class="tile is-child">
              <div v-if="story.wichtigeNachrichtAnzeigen" class="notification is-danger">
                <vue-simple-markdown class="textmarkdown" :source="story.wichtigeNachricht"></vue-simple-markdown>
              </div>
        </div>
        <div class="tile is-child">
          <p class="title">{{story.Title}}</p>
          <vue-simple-markdown :source="source"></vue-simple-markdown>
         </div>
      </div>
          </div>
          <div v-else class="box">
              <h1 class="title"> Loading . . .</h1>
          </div>
</div>
</template>

<script>

export default {
  name: 'Home',
  data(){
    return {
      story: null,
      pending: false
    }
  },
  computed:{
    logoURL(){
      
      return this.$axios.defaults.baseURL + this.story.Logo.url
    
    },
    FamilyPicURL(){
      
      return this.$axios.defaults.baseURL + this.story.Familie.formats.large.url
    },
    source(){
      return this.story.Geschichte
    }
  },
  async mounted() {
    this.pending = false
    this.story = await this.$get("/geschichte")
    this.pending = true
  },
  }
</script>

<style scoped>

</style>