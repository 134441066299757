import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import './scss/main.scss'
import axios from 'axios'
import VueAxios from 'vue-plugin-axios'
import VueSimpleMarkdown from 'vue-simple-markdown'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import x5GMaps from 'x5-gmaps'
// Option 1: Just your key


Vue.use(x5GMaps, 'AIzaSyDlFNq0Glm0GjHR3O36uh8V-8PLPU4Ga5E')
Vue.use(VueSimpleMarkdown)
Vue.use(VueAxios, {
  axios,
  config: {
    baseURL: 'https://cms.gasthausberger.at'
  }
})
Vue.use(Buefy)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
